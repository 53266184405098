import React, { Component } from 'react';
import {  MDBBtn,MDBIcon, MDBContainer,MDBDataTable  } from "mdbreact";
import {  Form, Backend, RulesInit, Roles } from "../../data/index";
import config from '../../config';

import {toast,ToastContainer} from 'react-toastify';
const {GetAllFormularyDataTable} = Form
const {GetPriceByService} = Backend
const {GetRulesInitByForm} = RulesInit
const {GetRolesByUserAndForm} = Roles

export default class Price extends Component {
    constructor(props){
        super(props)

        this.state = {
            data: [],
            modalLoading: false,
            modal: false,
            json: [],
            config:[],
        }

    }


    async componentDidMount(){
        const datagetter = await GetAllFormularyDataTable();
       if(datagetter.ok){
        this.setState({
            data: this.table(datagetter.data.Formulary)
          })
       }
        
        
    }


   async prices(id, idForm){

    const roles = await GetRolesByUserAndForm(localStorage.getItem("idUser"),idForm)
    if(!roles.data.rol){
      this.setState({
        progress: 0
      })
       toast.error('Usted no tiene permiso para ver este formulario', {
        position: toast.POSITION.BOTTOM_CENTER
      })
      return
    }
    const result = await  GetPriceByService(id);
    const rules = await GetRulesInitByForm(idForm)



    if(!result.ok){
        console.log('Error al buscar los precios',result);
        
        return
    }

    if(result.ok){
        this.props.history.push({
            pathname: '/allprices',
            prices: result.data.Price,
            rulesInit: rules.ok&& rules.data.rules
        })
    }

    }


    table(data){
       
        let array = []
            for (const i of data) {
              let element = {
                id: i.id,
                idForm: i.idForm,
                logo: <img alt="" className={" hoverable img-fluid"} width="80" src={i.url ? i.url: require('../../img/test.png')}/>,
                service: i.service_name,
                institution: i.institution_name,
                Accion: <MDBBtn  className="btn  btn-md text-white" color={config.color} onClick={()=>this.prices(i.idservice,i.id)}>ver precios</MDBBtn>,
              }
              array.push(element)
            }
        const column = [
            {label:'id',field:'id',sort:'asc'},
            {label:'formulario',field:'idForm',sort:'asc'},
            {label:'logo',field:'logo',sort:'asc'},
            {label:'Servicio',field:'service',sort:'asc'},
            {label:'Institucion',field:'institution',sort:'asc'},
            {label:'Accion',field:'Accion',sort:'asc'},
          ]

          const result = {columns:column, rows: array}
          return result
    }


    render() {
        return (
            <div className="form-group">
            <ToastContainer/>
            <br/>
            <br/>
            <h2>Configuracion de Precios</h2><br/>
            <MDBContainer>
            {(this.state.data) && (this.state.data.length===0) &&
                (<div className="col-md-12">
                    <MDBIcon icon="cog" spin size="3x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed /><br></br>
                    <br></br>
                    <b><h3>Cargando...</h3></b>
                </div>)
            }
                <MDBDataTable
                    bordered
                    hover
                    data={this.state.data}
                />
            </MDBContainer>
            </div>
            
        );
    }
}