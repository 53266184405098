import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './css/index.css';
import './css/tableMask.css';

export default class SimpleModalHeader extends Component {
   
    render(){
        return(
        <h4>
            <b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.props.text}
            </b>
        </h4>
        );
    }

}
