import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import {Form, Softexpert,Router} from "../../data/index";
import {  MDBIcon } from "mdbreact";
import config from '../../config';
import { getRouterPermission } from "../../Helpers/helpers";

const {getRouterByUser} = Router
const {GetAllFormularyDataTable } = Form;

export default class Permissions extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: 0 };
    }
  
  

    

      async componentDidMount(){
        console.log('permission didmount')
        this.setState({
            hasError: await this.authenticated()
        })
      }


    
    
    
        
    async  authenticated(){

        if(localStorage.getItem('idUser') && localStorage.getItem('token-rules')){
            const result = await getRouterByUser(localStorage.getItem('idUser'))
            const permission = getRouterPermission(result.data.routers, window.location.pathname.toLowerCase())
            
            if(permission){
                return  1
            }
            return 2
        }
        console.log('dffdfd');
        
        return 2   
        

        
       
    }
  
    render() {
      if (this.state.hasError === 0) {
        // Puedes renderizar cualquier interfaz de repuesto
        return(
            <MDBIcon icon="cog" spin size="4x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242', position: 'absolute', left: '46%', top: '45%'}} fixed />
        )
        
      }

      if (this.state.hasError === 2) {
        // Puedes renderizar cualquier interfaz de repuesto
        return (<Redirect to="/Home"/>)
      }
  
      return this.props.children; 
    }
}