//=================================== CHANGE ENVIRONMENT ==================================
    const option = 'development'
//=========================================================================================

function env(env) {
    switch (env) {
        case 'local':
            return ['http://localhost:4200/rules/api',env,'grey darken-1','http://des-apprr.serviciosrd.gob.do/']
        case 'server-lovera':
            return ['http://45.35.97.22/node/api-rules/rules/api',env,'secondary-color','http://des-apprr.serviciosrd.gob.do/']
        case 'development':
            // return ['http://45.229.148.33/rulesd',env,'blue darken-4','http://des-apprr.serviciosrd.gob.do/']
            return ['https://des-reglas-api.serviciosrd.gob.do',env,'blue darken-4','https://app-qr-serviciosrd.desarrollo.ogtic.gob.do/']

            
        case 'production':
            return ['http://45.229.148.33/rulesp',env, 'teal darken-1','http://apprr.serviciosrd.gob.do/']
        default:
            return ''
    }
}


try{
    module.exports ={

        api:    env(option)[0],
        env:    env(option)[1],
        color:  env(option)[2],
        qrApi: env(option)[3],
        SECRET_TOKEN: 'palolo'
    }
}catch(e){
}
