import config from "../../config";
import axios from "axios";

    function qrRedirect(idDocument,requestID) {
        let url = config.qrApi +'?doc='+idDocument+'&solicitud='+requestID;
        window.open(url, "_blank");
    }



    export {
        qrRedirect,
    }