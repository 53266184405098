import { Route, Switch, Redirect } from "react-router-dom";
import React from "react";




    const auth = ()=>{
        console.log('********************************************objectdd')
    const token = localStorage.getItem('token-rules')
    return token ? true : false
   }

    const PrivateRoute = (props) => (
        auth()
        ? <Route {...props}/>
        : <Redirect to="/login"/>
    )

    export default PrivateRoute