import config from "../../config";
import axios from "axios";

    function axios_api(api) {
    axios.defaults.baseURL = api;
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token-rules")}`; 
    return axios
    }




    async function sendForm(body) {
    console.log('data :', body);
    const result = await  axios_api(config.api+'/sendForms').post(config.api+'/sendForms',body).then((res)=>{
    return {'data':res.data,'ok':true};
    
    }).catch((error)=>{
    return {'data':error,'ok':false}
    });
    return result;
    
    }




    async function GetFormularyByServices(id) {
    const result = await axios_api(config.api+'/formularyByService/'+id).get(config.api+'/formularyByService/'+id).then((res)=>{        
    return {'data':res.data,'ok':true};
    }).catch((error)=>{
    return {'data':error,'ok':false};
    });
    return result;
    }


    async function GetFormularyByServiceBK(id) {
    const result = await axios_api(config.api+'/formulary/service/'+id).get(config.api+'/formulary/service/'+id).then((res)=>{        
        
        return {'data':res.data,'ok':true};
    }).catch((error)=>{
    return {'data':error,'ok':false};
    });
    return result;
    }



    async function GetPriceByService(id) {
        const result = await axios_api(config.api+'/formulary/price/'+id).get(config.api+'/formulary/price/'+id).then((res)=>{        
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
        return result;
        }



    function GetLastversionFormByService(id) {
    const result =  axios_api(config.api+'/lastversionform/'+id).get(config.api+'/lastversionform/'+id).then((res)=>{
    return {'data':res.data,'ok':true};
    }).catch((error)=>{
    return {'data':error,'ok':false};
    });
    return result;
    }




    function GetLastAllInstitutions() {
    const result =  axios_api(config.api+'/AllInstitutions').get(config.api+'/AllInstitutions').then((res)=>{
    return {'data':res.data,'ok':true};
    }).catch((error)=>{
    return {'data':error,'ok':false};
    });
    return result;
    }




    function GetServicesByInstituion(id) {
    const result =  axios_api(config.api+'/servicesByInstituion/'+id).get(config.api+'/servicesByInstituion/'+id).then((res)=>{  
    return {'data':res.data,'ok':true};
    }).catch((error)=>{
    return {'data':error,'ok':false};
    });
    return result;
    
    }


    function GetServiceById(id) {
    const result =   axios_api(config.api+'/service/'+id).get(config.api+'/service/'+id).then((res)=>{
    return {'data':res.data,'ok':true};
    }).catch((error)=>{
    return {'data':error,'ok':false};
    });
    return result;
    }



    function GetInstitutionById(id) {
    const result =  axios_api(config.api+'/institution/'+id).get(config.api+'/institution/'+id).then((res)=>{
    return {'data':res.data,'ok':true};
    }).catch((error)=>{
    return {'data':error,'ok':false};
    });
    return result;
    }








export {
    sendForm,
    GetLastversionFormByService,
    GetLastAllInstitutions,
    GetServicesByInstituion,
    GetServiceById,
    GetInstitutionById,
    GetFormularyByServices,
    GetFormularyByServiceBK,
    GetPriceByService
}