import React, { Component } from 'react';
import { MDBRow, MDBCol,MDBIcon,MDBBtn
    ,MDBModal,MDBModalHeader,MDBModalBody, MDBCard  } from "mdbreact";
import { RulesPrice } from "../../data/index";
import config from '../../config';
import {  toast } from 'react-toastify'

const {saveRulesPrice,GetRulesPriceByPrice,setRulesPriceSelected,removeRulesPriceSelected, getRulesPriceSelected} = RulesPrice   

export default class allVariations extends Component {
    constructor(props){
        super(props)

        this.state = {
            data: [],
            modalLoading: false,
            modal: false,
            json: [],
            prices:[],
            ruleinits:[],
            min: 0,
            max:0
        }

    }


    async componentDidMount(){
        console.log('this.props alallal :', this.props);
        // if(!this.props.location.prices){
        //     return
        // }
        // if(this.props.location.prices.length>0){
        //     let prices = this.props.location.prices
        //     for (const key in prices) {
        //         setTimeout( await this.DataSelected(prices[key].id), 9000);
        //         console.log('hola!!!');
                
        //     }

        //     this.setState({
        //         prices: this.props.location.prices,
        //         ruleinits: this.props.location.rulesInit
        //     })
        // }
        
    }

    componentWillReceiveProps(nextprops){
            if(nextprops.price ){
                let variations = []
                let prices = this.props.data.prices 
                let ruleinit = this.props.data.rulesInit

                for (const key in prices) {
                    if(prices[key].id === nextprops.price){
                        variations = prices[key].variations
                    }
                }

                for (const key in variations) {
                    this.DataSelected(variations[key].id)
                            
                }

                this.setState({
                    prices: variations,
                    ruleinits: ruleinit
                })
            }
            
        
    }




    async DataSelected(id){
        const result = await getRulesPriceSelected(id,'variations')
        if(result.data.rules){
            let attribute = result.data.rules.attribute
            if(attribute){
                let data = result.data.rules.attribute.split('&')
                document.getElementById('min'+result.data.rules.idPrice)&& (document.getElementById('min'+result.data.rules.idPrice).value = data[0])
                document.getElementById('max'+result.data.rules.idPrice)&&( document.getElementById('max'+result.data.rules.idPrice).value = data[1])
                document.getElementById('priceExtra'+result.data.rules.idPrice)&& (document.getElementById('priceExtra'+result.data.rules.idPrice).value = data[2])
                document.getElementById('pricestatic'+result.data.rules.idPrice)&&(document.getElementById('pricestatic'+result.data.rules.idPrice).checked = JSON.parse(data[3]))
            }
            console.log('attibute :', attribute);
            this.setState({
                [id+'price']: result.data.rules.idRuleinit
            })

        }
    }



    fontSize(n){
        console.log('n :', n);
        if(n>30){
            return 13
        }
        return 19
    }


    async setRule(e, type,){
        let price = e.target[e.target.selectedIndex].getAttribute('price-id')
        let rule = e.target[e.target.selectedIndex].getAttribute('rule-id')
        let form = e.target[e.target.selectedIndex].getAttribute('form-id')
        let option = e.target[e.target.selectedIndex].getAttribute('option-id')
        let min = document.getElementById('min'+price);
        let max = document.getElementById('max'+price);
        let staticprice = document.getElementById('pricestatic'+price);
        console.log('staticprice', staticprice.checked)
        let priceExtra = document.getElementById('priceExtra'+price);
        if(option==='no-rule'){
            let bodyRemove = {
                idPrice: price,
                typePrice:type
            }
            const remove = await removeRulesPriceSelected(bodyRemove)
            console.log('remove.data :', remove.data);
            await toast.info('La regla fue removida', {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return
        }

        const resulPrice = await GetRulesPriceByPrice(price,rule,type);
        
        if(resulPrice.data.rules){
        let bodyEdit = {
            idPrice: price,
            id:resulPrice.data.rules.id,
            selected: 1,
            typePrice:type,
            attribute: min.value+'&'+ max.value+'&'+priceExtra.value+'&'+staticprice.checked
        }
    
        const resultSelected = await setRulesPriceSelected(bodyEdit)
        console.log('resultSelected :', resultSelected);
        await toast.info('Se actualizo correctamente la regla', {
            position: toast.POSITION.BOTTOM_CENTER
        });
        
        }
        

        if(!resulPrice.data.rules){
            let body ={
                idForm: form,
                idRuleinit: rule,
                idPrice: price,
                typePrice:type,
                selected: 1,
                attribute: min.value +'&'+ max.value + '&'+priceExtra.value
            }
            await saveRulesPrice(body)
            await toast.success('Se asigno correctamente la regla', {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
        
    }



    render() {
        return (
        <MDBModal isOpen={this.props.modal} toggle={this.props.toggle} size="fluid" >
            <MDBModalHeader toggle={this.props.toggle}>Variaciones de precio del precio no. {this.props.price}</MDBModalHeader>
            <MDBModalBody>
            <div className="form-group" style={{padding: '10px'}}>
            <MDBRow>
                {this.state.prices.length===0&&(
                    <MDBCol size="12">
                        <h5> este servicio no tiene precios</h5>
                        
                    </MDBCol>
                )}
                {this.state.prices.length>0&&
                    this.state.prices.map((p,i)=>(
                        <MDBCol size="3"   >
                            <select id={ 'price'+p.id} onChange={(e)=> this.setRule(e, 'variations')} className="form-control">
                                <option option-id={"selected"} price-id={p.id}>Seleccione una regla</option>
                                <option option-id={"no-rule"} price-id={p.id}>Enviar sin reglas</option>
                                {this.state.ruleinits.length>0&&
                                this.state.ruleinits.map((rule,i)=>(
                                    <option
                                        option-id={"yes-rule"}
                                        rule-id={rule.id} 
                                        form-id={rule.idForm} 
                                        price-id={p.id} 
                                        value={rule.id}
                                        selected={rule.id=== this.state[p.id+'price']?true:false}
                                        key={i}>
                                        regla no. {rule.id}
                                    </option>
                                ))}
                                    
                            </select>
                            <br/>
                            <MDBCard  className={this.state[i+'shadow'] + ' cursor'} onMouseOut={()=> this.setState({[i+'shadow']: ''})} onMouseEnter={()=> this.setState({[i+'shadow']: 'z-depth-2'})}   style={{height: 'auto', alignSelf: 'left', alignContent: 'left'}} >
                            <div onMouseOut={()=> this.setState({[i+'shadow']: ''})} onMouseEnter={()=> this.setState({[i+'shadow']: 'z-depth-2'})}  style={{ alignSelf: 'left', alignContent: 'left',   paddingLeft: 1, paddingRight: 1,color: 'white',backgroundColor: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}><label>{p.id}</label></div>
                            <br/><br/>
                            <MDBIcon onMouseOut={()=> this.setState({[i+'shadow']: ''})} onMouseEnter={()=> this.setState({[i+'shadow']: 'z-depth-2'})}    icon="hand-holding-usd" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}} size="4x" />
                            <br/>
                                    <label><b>Atributos (OPCIONAL):</b></label>
                                <MDBRow className="padding" style={{padding: '10px'}}>
                                    <MDBCol>
                                        Desde:  
                                        <input type="number"  id={"min"+p.id} className="form-control"/><br/>
                                    </MDBCol>
                                    <MDBCol>
                                        Hasta:  
                                        <input type="number"  id={"max"+p.id} className="form-control"/><br/>
                                    </MDBCol>
                             
                                </MDBRow>
                                <MDBRow className="padding" style={{padding: '10px'}}>
                                    <MDBCol>
                                        Precio Extra:  <br/><br/>
                                        <input type="number"  id={"priceExtra"+p.id} className="form-control"/><br/>
                                    </MDBCol>
                                    <MDBCol>
                                        Precio fijo: <br/> <br/> 
                                       <center>
                                        <input type="checkbox"  id={"pricestatic"+p.id} className="form-control"/><br/>
                                       </center>
                                    </MDBCol>
                             
                                </MDBRow>

                                <MDBRow className="padding">
                                    <MDBCol>
                                        <button className="btn btn-success btn-sm" onClick={(e)=> this.setRule({target: document.getElementById('price'+p.id)}, 'variations')}>Guardar cambios</button><br/>
                                    </MDBCol>
                             {}
                                </MDBRow>
                            <br/>
                            <div onMouseOut={()=> this.setState({[i+'shadow']: ''})} onMouseEnter={()=> this.setState({[i+'shadow']: 'z-depth-2'})}    style={{ alignSelf: 'left', alignContent: 'left',  paddingLeft: 1, paddingRight: 1,color: 'white',backgroundColor: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}><label onMouseOut={()=> this.setState({[i+'shadow']: ''})} onMouseEnter={()=> this.setState({[i+'shadow']: 'z-depth-2'})}  style={{alignSelf: 'center', }}> { p.concept.length>44?p.concept.substr(0, 70) +'...' : p.concept}</label></div>
                            </MDBCard>
                            <br/><br/>
                        </MDBCol>
                ))}
            </MDBRow>
            </div>
            </MDBModalBody>

        </MDBModal>
        )
    }
}