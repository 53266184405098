import React, { Component } from 'react';
import { MDBRow, MDBCol,MDBIcon,MDBBtn
    , MDBCard  } from "mdbreact";
import { RulesPrice } from "../../data/index";
import config from '../../config';
import {  toast,ToastContainer } from 'react-toastify'
import Variations from './allVariations';

const {saveRulesPrice,GetRulesPriceByPrice,setRulesPriceSelected,removeRulesPriceSelected, getRulesPriceSelected} = RulesPrice   

export default class allPrice extends Component {
    constructor(props){
        super(props)

        this.state = {
            data: [],
            modal: false,
            json: [],
            prices:[],
            ruleinits:[],
            idPrice: 0
        }


        this.openModal = this.openModal.bind(this)

    }


    async componentDidMount(){
        console.log('this.props :', this.props.location);
        if(!this.props.location.prices){
            return
        }
        if(this.props.location.prices.length>0){
            let prices = this.props.location.prices
            for (const key in prices) {
                this.DataSelected(prices[key].id)
                
            }

            this.setState({
                prices: this.props.location.prices,
                ruleinits: this.props.location.rulesInit
            })
        }
        
    }


    openModal(id){
        

        if(this.state.modal){
            this.setState({
                modal: !this.state.modal
            })
            return
        }
        
        this.setState({
            modal: !this.state.modal,
            idPrice: id&&id
        })
    }




    async DataSelected(id){
        const result = await getRulesPriceSelected(id,'price')
        if(result.data.rules){
            this.setState({
                [id+'price']: result.data.rules.idRuleinit
            })

        }
    }



    fontSize(n){
        if(n>30){
            return 13
        }
        return 19
    }


    async setRule(e, type){
        let price = e.target[e.target.selectedIndex].getAttribute('price-id')
        let rule = e.target[e.target.selectedIndex].getAttribute('rule-id')
        let form = e.target[e.target.selectedIndex].getAttribute('form-id')
        let option = e.target[e.target.selectedIndex].getAttribute('option-id')
      
        if(option==='no-rule'){
            let bodyRemove = {
                idPrice: price,
                typePrice:type
            }
            const remove = await removeRulesPriceSelected(bodyRemove)
            console.log('remove.data :', remove.data);
            await toast.info('La regla fue removida', {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return
        }

        const resulPrice = await GetRulesPriceByPrice(price,rule,type);
        
        if(resulPrice.data.rules){
        let bodyEdit = {
            idPrice: price,
            id:resulPrice.data.rules.id,
            selected: 1,
            typePrice:type
        }
        const resultSelected = await setRulesPriceSelected(bodyEdit)
        console.log('resultSelected :', resultSelected);
        toast.info('Se actualizo correctamente la regla', {
            position: toast.POSITION.BOTTOM_CENTER
        });
        
        }

        if(!resulPrice.data.rules){
            let body ={
                idForm: form,
                idRuleinit: rule,
                idPrice: price,
                typePrice:type,
                selected: 1
            }
             await saveRulesPrice(body)
            toast.success('Se asigno correctamente la regla', {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
        
    }



    render() {
        return (
            <div className="form-group">
            <ToastContainer/>
            <h2>Precios</h2><br/>
            <MDBRow>
                {this.state.prices.length===0&&(
                    <MDBCol size="12">
                        <h5> este servicio no tiene precios</h5>
                        <MDBBtn className="btn  btn-md text-white" color={config.color} onClick={() => this.props.history.push('/Price')}>volver</MDBBtn>
                    </MDBCol>
                )}
                {this.state.prices.length>0&&
                    this.state.prices.map((p,i)=>(
                        <MDBCol size="3"   >
                            
                            <br/>
                            <MDBCard  className={this.state[i+'shadow'] + ' cursor'} onMouseOut={()=> this.setState({[i+'shadow']: ''})} onMouseEnter={()=> this.setState({[i+'shadow']: 'z-depth-2'})}   style={{height: 'auto', alignSelf: 'left', alignContent: 'left'}} onClick={()=> this.openModal(p.id)}>
                            <div onMouseOut={()=> this.setState({[i+'shadow']: ''})} onMouseEnter={()=> this.setState({[i+'shadow']: 'z-depth-2'})}  style={{ alignSelf: 'left', alignContent: 'left',   paddingLeft: 1, paddingRight: 1,color: 'white',backgroundColor: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}><label>{p.id}</label></div>
                            <br/><br/>
                            <MDBIcon onMouseOut={()=> this.setState({[i+'shadow']: ''})} onMouseEnter={()=> this.setState({[i+'shadow']: 'z-depth-2'})}    icon="dollar-sign" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}} size="4x" />
                            <br/>    <br/>
                            <div onMouseOut={()=> this.setState({[i+'shadow']: ''})} onMouseEnter={()=> this.setState({[i+'shadow']: 'z-depth-2'})}    style={{ alignSelf: 'left', alignContent: 'left',  paddingLeft: 1, paddingRight: 1,color: 'white',backgroundColor: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}><label onMouseOut={()=> this.setState({[i+'shadow']: ''})} onMouseEnter={()=> this.setState({[i+'shadow']: 'z-depth-2'})}  style={{alignSelf: 'center', }}> { p.concept.length>44?p.concept.substr(0, 70) +'...' : p.concept}</label></div>
                            </MDBCard>
                            <br/><br/>
                        </MDBCol>
                ))}
            </MDBRow>
            <Variations toggle={this.openModal} data={this.props.location} modal={this.state.modal} price={this.state.idPrice}/>
            </div>
            
        );
    }
}