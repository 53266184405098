import React, { Component } from 'react';
import {MDBCol,MDBIcon,MDBContainer,MDBAnimation ,MDBModal,MDBModalHeader,MDBModalBody,MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader, MDBCardFooter, MDBBtn,} from "mdbreact";
import CardText from '../Utilities/CardText'; 
import { Redirect } from 'react-router-dom';
import { Rules  } from "../../data/index";
import config from '../../config';
const {GetRulesById, deleteRulesById} = Rules
class RulesListBox extends Component {
    constructor(props){
        super(props)
        this.state = {
            shadow: '',
            newRule: false,
            rules:'',
            modal: '',
            loading: false,
            efect: "bounceInUp"
        }
    }
    async  viewRule(){
      const result = await GetRulesById(this.props.rules.id)
        this.setState({
          modal: true,
          rules: result.data.rules[0].rule
        })
      }

      toggle = () => {
        this.setState({
          modal: !this.state.modal
        });
      }



  async delete(id){
    this.setState({
      loading: true
    })
    const result = await deleteRulesById(id);
    const handler =  await this.props.handlerRule();
    this.setState({
      loading: false,
      efect: "bounceOutUp"
    })
    
  }
  render() {
    if(this.state.newRule){
      return(
        <Redirect to={{
            pathname: '/render',
            state: {data: this.props.data, idForm: this.props.idForm, idRule: this.props.rules.id }
          }} 
        />
      )
    }

   
    return (
          <MDBCol      size="3">
          <br/>
          <MDBAnimation type={this.state.efect}>
        <MDBCard style={{ marginTop: "1rem" }} className="text-center">
          <MDBCardHeader color={config.color} ><MDBIcon onClick={()=>this.viewRule()}  onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})} icon="eye" style={{color: 'white'}} size="1x" className=" cursor"/></MDBCardHeader>
          <MDBCardBody>
            <MDBCardTitle>{' version: ['+this.props.rules.versionRule+']'}</MDBCardTitle>
            <MDBBtn color="success" size="sm"  onClick={()=> this.setState({newRule: true})}>
              Abrir regla
            </MDBBtn>
            <MDBBtn color="danger" size="sm" onClick={(e)=> this.delete(this.props.rules.id)}>
              Borrar regla
            </MDBBtn>
            {this.state.loading&&(
              <MDBIcon icon="cog" spin size="1x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}} />
            )}
          </MDBCardBody>
          <MDBCardFooter color={config.color}><b>creada el:</b> {this.props.rules.createdAt.split('T')[0] } - {this.props.rules.createdAt.split('T')[1].substring(0, 5) }</MDBCardFooter>
        </MDBCard>
        </MDBAnimation>

        {/**
          ==============================================MODAL IMPORT RULE==========================
        */}        
          <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                  <MDBModalHeader toggle={this.toggle}>Regla version no. {this.props.rules.versionRule}</MDBModalHeader>
                  <MDBModalBody>
                    <textarea disabled className="form-control" rows="4">
                    {this.state.rules}
                    </textarea>
                  </MDBModalBody>
                </MDBModal>
              </MDBContainer>
            </MDBCol>
    );
  }
}

export default RulesListBox;