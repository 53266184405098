import React, { Component } from 'react';
import { TMaskDate,TMaskText,TMaskTime } from "../form/index";
import { MDBRow,MDBBtn, MDBCol,MDBModal,MDBInput,MDBModalBody, MDBModalHeader,MDBModalFooter } from "mdbreact";
import {Badge} from'./maskBadges';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/index.css';
import {MaskSelectOption} from './maskSelectOption'
import SimpleModalHeader from './simpleModalHeader'
import './css/tableMask.css';
import  CardText  from "../Utilities/CardText";
import { array } from 'prop-types';
import {  Softexpert, Mask } from "../../data/index";
const {GetLatestMaskVersion, GetAllMaskVersionsPerForm} = Mask;
const {saveMask, GetFormularyBySE,} = Softexpert;
class MaskGridFields extends Component {
    constructor(props){
        super(props)
        this.state = {
            active:false,
            id:'',
            name:'',
            mask:"0",
            label:'',
            gridFields:[],
            fieldsConfig:[],
            maskConfig:[],
            maskVersionsGrid:[],
            version:'',
            reset:true
        }
        this.selectedField =  this.selectedField.bind(this)
        this.removeSelected =  this.removeSelected.bind(this)
        this.mask =  this.mask.bind(this)
        this.setAll =  this.setAll.bind(this)
    }
    async componentWillReceiveProps(){
        this.setState({
            gridFields: this.props.gridFields,
            maskVersions:await this.props.maskVersionsGrid,
            version:this.props.version
        })
        console.log(this.state.gridFields,'the version')
        let element = await document.getElementById('gridButton')
        if(typeof(this.props.gridFields[0]) === 'object'){
            console.log('es un objeto')
        } else {
            element.setAttribute("disabled",true)
        }
        this.setAll()
    }

    setAll(){
        let element 
        this.state.fieldsConfig = []    
        try{
            this.setState({
                fieldsConfig:this.props.maskVersionsGrid
            })
        }catch(e){}
        for(const i in this.state.fieldsConfig){
           console.log(this.state.fieldsConfig[i]['name'],'iterando')
           element = document.getElementById(this.state.fieldsConfig[i]['name'])
           try{
           console.log(element)
            element.setAttribute("style", "display:block; border-style: dotted;border-width: 3px;"
            + "border-color:#16B9B3; background-color:lightgreen;");
           }catch(e){
           }
       }
    }
    async selectedField(id, name , group, mask , active,label){
        let element = await document.getElementById(name),
        fieldsConfig =  await this.state.fieldsConfig
        console.log(fieldsConfig,'dd')
        for (const i in fieldsConfig){
            console.log(fieldsConfig[i]['name'], name)
            console.log(fieldsConfig[i]['id'], id)
            console.log(fieldsConfig[i]['label'],label)
            if(fieldsConfig[i]['label'] ===label 
            && fieldsConfig[i]['id'] ===id 
            && fieldsConfig[i]['name'] === name){
                await toast.warning(`Debe remover para reasignar`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return
            } 
        }
        fieldsConfig =  {
            "id":id,
            "name":name,
            "mask":mask,
            "group":group,
            "label":label
        }
        this.state.fieldsConfig.push(fieldsConfig);
        element.setAttribute("style", "display:block; border-style: dotted;border-width: 3px;"
        + "border-color:#16B9B3; background-color:lightgreen;");
        console.log(this.state.fieldsConfig)
    }
    toggleGridFields = () =>{
        this.setState({
          modalGrids: !this.state.modalGrids
        }); 
      }
    mask(e){
        this.setState({
        mask: e.target.value
        })
      }
    async removeSelected(){
        this.state.reset = false
        try{
            let fieldsConfig = this.state.fieldsConfig,
            index  = fieldsConfig.length-1,
            name = fieldsConfig[index]['name']
            let element = document.getElementById(name)
            element.removeAttribute("style", true);
            fieldsConfig.pop()
        }catch(e){
            await toast.warning(`Campos sin mascaras asignadas`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    } 
    render(){
        return (
            <MDBCol>
            <MDBModal isOpen={this.props.modalGrids}  className="modal-dialog" toggle={this.props.toggleGridFields} size="lg" >
                <MDBModalHeader toggle={this.props.toggleGridFields}>
                    <SimpleModalHeader text={'Asig. Máscaras a Grids'}/>
                </MDBModalHeader>
                <MDBModalBody className="modal-body"/*modal-body*/>
                    <MDBRow className="marginSelected col-center">
                        {this.props.gridFields.map((field, i) =>
                        field.type === 'header' && field.label!==null ? 
                                <MDBCol size='12'>
                                    <CardText color="#00695c" fs={false} text={field.label} />
                                    <div className="mt-4"></div>     
                                </MDBCol>:
                                field.type === 'text'? (
                                    <MDBCol size="3"  className="cursor" onClick={(e) => this.selectedField(i ,
                                    field.name,field.group, this.state.mask, true ,field.label)}> 
                                    {/* <span class="badge badge-primary">{ field.group}</span> */}
                                    <div className="mt-2"></div>
                                    <Badge name={field.name} maskValue={this.props.asignTemporalMask} i={i} /> 
                                        <TMaskText  key={i} class="refresh" oldValue={field.mask} name={field.name} 
                                        id={field.name} field={field} />
                                    </MDBCol>
                                ):
                                field.type === 'time' ? (
                                    <MDBCol size="3"  className="cursor" onClick={(e) => this.selectedField(i ,
                                    field.name,field.group, this.state.mask, true, field.label)}> 
                                    {/* <span class="badge badge-primary">{field.group}</span> */}
                                    <div className="mt-2"></div>
                                    <Badge name={field.name} maskValue={this.props.asignTemporalMask} i={i} />
                                        <TMaskTime key={i} oldValue={field.mask} name={field.name} id={field.name} field={field}/>
                                    </MDBCol>
                                ):
                                field.type === 'date' &&(
                                    <MDBCol size="3"  className="cursor" onClick={(e) => this.selectedField(i ,
                                    field.name,field.group, this.state.mask, true, field.label)}> 
                                    <div className="mt-2"></div>
                                    <Badge name={field.name} maskValue={this.props.asignTemporalMask} i={i} />
                                        <TMaskDate key={i} oldValue={field.mask} name={field.name} id={field.name} field={field}/>
                                    </MDBCol>
                                )
                            )
                        }
                    </MDBRow>
                    </MDBModalBody>  
                    <MDBModalFooter>
                        <b>Seleccione:</b>
                    <MaskSelectOption  mask={this.mask} value={this.state.mask} /> {/*Listado de mascaras en componente aparte*/}
                        <MDBBtn  size="md" color="danger"onClick={()=> this.removeSelected()}>Remover</MDBBtn>
                        <MDBBtn  size="md" color="primary" 
                        onClick={()=> this.props.asignConfig(this.state.fieldsConfig)}>Guardar asignación</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBCol>
        ); 
    }
}export default MaskGridFields
