import React, { Component } from 'react';
import {  MDBIcon } from "mdbreact";
import config from '../../../config'
export default class FText extends Component {

    constructor(props){
      super(props)

      this.state = {
        selected:{
          borderColor: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242',
          borderStyle: 'dotted',
          borderWidth: 2
        } 
      }
    }
    componentWillReceiveProps(props){
    }


    onCLickRule(e,name, ){
      if(e.target.getAttribute("data-selected")==='false'){
        this.props.handlerFromInput(e,name)
        e.target.style="color: #00695c"
        e.target.setAttribute('data-selected', 'true')
        return
      }

      if(e.target.getAttribute("data-selected")==='true'){
        this.props.handlerFromInput(e,"")
        e.target.style="color: black"
        e.target.setAttribute('data-selected', 'false')
        return
      }
      console.log('e.target :', e.target.getAttribute("data-selected"));
      //fieldFromInput
    }
    render() {
      const field = this.props.field
      const i = this.props.id
      return (
          <div className="form-group" >
          {this.props.textForMask?(
           <div onClick={(e)=>this.props.handlerFromInput(e, field, this.props.grid, this.props.nameGrid ,this.props.setModal)}>
           <label><b>{field.label}</b> <span id={'rule'+field.name}></span></label><label  class="float-right">mascara: <span style={{ fontSize: 12, color: 'green', fontWeight: 'bold'}}>{this.props.fieldSave[this.props.grid? this.props.nameGrid+'_'+field.name: field.name]?(this.props.fieldSave[this.props.grid?this.props.nameGrid+'_'+field.name: field.name].Mask&&this.props.fieldSave[this.props.grid?this.props.nameGrid+'_'+field.name: field.name].Mask.label):('')}</span></label><br/>
              <input
                type="text"
                id={i}
                key={i}
                name={'F'+field.name}
                placeholder={'id: '+ (field.name)}
                style={
                   this.props.fieldFromInput&&field.name === this.props.fieldFromInput.name? this.state.selected:{}}
                disabled="disabled"
              />
              <label class="float-left" style={{marginTop:'-25px', fontSize: 12, color: 'green',fontWeight: 'bold'}}>{this.props.fieldSave[this.props.grid? this.props.nameGrid+'_'+field.name: field.name]&&('Este campo ha sido modificado')}</label>
           </div>
          )
          :(
             <div>
             <label><b>{field.label}</b> <span id={'rule'+field.name}></span></label><br/>
              <MDBIcon icon="arrow-alt-circle-left" data-selected={false} id={'I|'+ field.name} onClick={e =>  this.props.handlerFromInput(field.name, field.type, {label: 'ENTRADA', value: 'I'}, field.label,e)}/> <MDBIcon icon="arrow-alt-circle-right" id={'O|'+ field.name} data-selected={false} onClick={e =>  this.props.handlerFromInput(field.name, field.type, {label: 'SALIDA', value: 'O'}, field.label,e)} />
              <input
                type="text"
                id={i}
                key={i}
                name={'F'+field.name}
                placeholder={'id: '+ (field.name)}
                disabled="disabled"
              />
             </div>
          )}
          
        </div>
      );
    }
  }
  
  