import React, { Component } from 'react';
import CardText from "../../Utilities/CardText";
import config from '../../../config';
export default class FHeader extends Component {
    componentWillReceiveProps(props){
    }
    render() {
      const field = this.props.field
      const i = this.props.id
      return (
          <div className="form-group" >
          {field.subtype === 'h1' ? (
            <CardText key={i} id={field.name}  fs={false} color={config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'} text={field.label}/>
          )
          :
          (
            <CardText key={i} id={field.name} fs={true} color="#bdbdbd " text={field.label }/>
          )
        
        
        }
            
           
        </div>
      );
    }
  }