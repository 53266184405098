import React from "react";
import { Route, Switch } from "react-router-dom";

import App from "./component/app/App";
import Rules from "./component/rules/rules";
//import Page404 from "./component/404/Page404";
import Home from "./component/Home/home";
import MasksTable from "./component/Masks/masksTable";
import Mask from './component/Mask/maskTable';
import MaskList from './component/Masks/maskList'
import Maskbuilder from './component/Masks/maskbuilder';
import AllFormulary from "./component/formulary/allFormulary";
//import RenderRules from './component/rules/renderRules';
import QR from './component/QR/QR';
import Mails from './component/Mails/Main';
import Admin from './component/admin/admi';
import RequireAuth from './component/RequireAuth/RequireAuth';
import Configurations from './component/formConfigurations/configurations';
import Price from './component/price/price';
import allPrice from './component/price/allPrice';
import Docs from './component/docs/docs';
import Tranfer from './component/transfer/tranfer';
import Users from './component/admin/users';
import Login from './component/login/login';
import Test from './component/Test/test';
import RulesBuilder from './component/rules/rulesBuilder';
import PrivateRoute from './routerPrivate';
import Auth from './component/Permission/auth';
import Permissions from './component/Permission/permission'

const AppRoutes = () =>
    <Auth>
            <App>
                <Switch>
                    <PrivateRoute path="/Rules" component={Rules}/>
                    <PrivateRoute path="/testRule" component={Test}/>
                    <PrivateRoute path="/Docs" component={Docs}/>
                    <PrivateRoute path="/tranfer" component={Tranfer}/>
                    <PrivateRoute path="/Price" component={Price}/>
                    <PrivateRoute path="/allprices" component={allPrice}/>
                    <PrivateRoute path="/Mask" component={MasksTable}/>
                    <PrivateRoute path="/masklist" component={MaskList}/>
                    <PrivateRoute path="/Formulary" component={RequireAuth(AllFormulary)}/>
                    <PrivateRoute path="/admin" component={Admin}/>
                    <PrivateRoute path="/render" component={RulesBuilder}/>
                    <PrivateRoute path="/Mails" component={Mails}/>
                    <PrivateRoute path="/QR" component={QR}/>
                    <PrivateRoute path="/Configurations" component={Configurations}/>
                    <PrivateRoute path="/users" component={Users}/>
                    <PrivateRoute path="/home" component={Home}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/maskbuilder" component={Maskbuilder}/>
                    <Route path="/" exact  component={Login}/>
                </Switch>
            </App>
        
    </Auth>
export default AppRoutes