import React, { Component } from 'react';
import config from '../../config';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody, MDBIcon } from 'mdbreact';
import { User,Router,Softexpert } from "../../data/index";
import { toast, ToastContainer } from 'react-toastify';
const { logiForToken } = User;
const {saveRouter} = Router
class login extends Component {

    constructor(props){
        super(props)
        this.state = {
          isOpen: false,
          user: '',
          pass: '',
          loading: false,
          isLogin: false,
          nameUser: '',
          activeItem: "1",
          modalForget: false,
          userLoggedIn:''
      
        };
        this.UserInput = this.UserInput.bind(this);
        this.PassInput = this.PassInput.bind(this);
      }

    UserInput(e){
        this.setState({
          user: e.target.value
        })
      }
      PassInput(e){
        this.setState({
          pass: e.target.value
        })
      }

      async componentDidMount(){
        console.log('*******');
        
      }



  

    async Login(){
        this.setState({
          loading: true
        })
        
      
        
          const result = await logiForToken(this.state.user, this.state.pass)
          console.log('token :', result);
      
          if(result.ok){
            localStorage.setItem('token-rules', result.data.token_rules)
            localStorage.setItem('nameUser', result.data.user.user.firstname + ' ' + result.data.user.user.lastname)
            localStorage.setItem('idUser', result.data.user.user.id)
            localStorage.setItem('isLogin', true)
            localStorage.setItem('rol', 2)
            localStorage.setItem('userLoggedIn', result.data.user.user.email)
            let body = {
              idUser: result.data.user.user.id,
              router: "dash"
            }
            const router =  await saveRouter(body)
        
            this.setState({
              modal: false,
              isLogin:  result.data.user.auth,
              nameUser: result.data.fistname,
              rol: 2,
              userLoggedIn:result.data.email
            })
            window.location.href = '/home'
          }else{
            this.setState({
              loading: false,
            })
            toast.error('Este usuario no esta registrado en el portal administrativo',{
              position: toast.POSITION.BOTTOM_CENTER
            })
          }
      
      
      }
      logout(){
          this.setState({
            isLogin: false,
            nameUser:'',
            redirect: true
          })
          localStorage.setItem('token-rules','')
            localStorage.setItem('nameUser', '')
            localStorage.setItem('idUser', '')
            localStorage.setItem('isLogin', false)
            localStorage.setItem('rol', 0)
            localStorage.setItem('userLoggedIn', false)
      }

      shouldComponentUpdate(nextProps, nextState){
        return ((this.state !== nextState) && (this.props !== nextProps))
      }
    render() {

        return (
            <div  id="login" style={{height: 800}}>
            <ToastContainer/>
                <MDBContainer>
                <MDBRow>
                
                <MDBCol md="3"></MDBCol>
                <MDBCol md="6">
                <br/>
                
                
                <br/>
                    <MDBCard>
                    <div style={{backgroundColor: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242', height: 200}}>
                    <br/>
                    <h2 style={{color: 'white'}}><b>Aplicacion de reglas</b></h2>
                    <h6 style={{color: 'white'}}>Ambiente: {config.env}</h6>
                    </div>
                    <MDBCardBody>
                   
                        
                        <img className="img-fluid" alt="" style={{marginTop: -100, border: 'solid', borderColor: 'white', borderRadius: 10, borderWidth: 10}} width="140" src={"https://noticiassin.com/wp-content/uploads/2014/07/OPTIC.png"} />
                        <br/>
                        <p
                            htmlFor="defaultFormCardNameEx"
                            className=" grey-text text-left "
                        >
                        <b>
                        &nbsp;&nbsp;Correo:
                        </b>
                           
                        </p>
                        <input
                            type="email"
                            id="defaultFormCardNameEx"
                            className="form-control"
                            onChange={(e)=>this.UserInput(e)}
                            style={{borderColor: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242', borderWidth: 3, height:40, borderRadius: 10}}
                        />
                        <br />
                        <p
                            htmlFor="defaultFormCardEmailEx"
                            className="grey-text text-left"
                        >
                        <b>
                        &nbsp;&nbsp;Contraseña:
                        </b>
                            
                        </p>
                        <input
                            type="password"
                            id="defaultFormCardEmailEx"
                            className="form-control"
                            onChange={(e)=>this.PassInput(e)}
                            style={{borderColor: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242', borderWidth: 3, height:40, borderRadius: 10}}
                        /><br/>
                        {this.state.loading&&(
                          <div>
                            <MDBIcon icon="cog" spin size="2x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed />
                            <label>cargando....</label>
                          </div>
                          )}
                        <div className="text-center py-4 mt-3">
                            <MDBBtn onClick={()=>this.Login()} className={"btn "+config.color+" btn-block"} type="submit">
                            Entrar
                            </MDBBtn>
                        </div>
                    </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol md="3"></MDBCol>
                </MDBRow>
            </MDBContainer>
            
            </div>
        );
    }
}

export default login;