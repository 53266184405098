import React, { Component } from 'react';
import {MDBCol,MDBIcon,MDBContainer,MDBModal,MDBModalHeader,MDBModalBody,MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader, MDBCardFooter, MDBBtn,} from "mdbreact";
import CardText from '../Utilities/CardText'; 
import { Redirect } from 'react-router-dom';
import { Mask  } from "../../data/index";

import {  toast,ToastContainer} from 'react-toastify';
import config from '../../config';
const {deleteMaskById} = Mask
class maskBox extends Component {
    constructor(props){
        super(props)
        this.state = {
            shadow: '',
            newRule: false,
            rules:'',
            modal: '',
            newMask: false
        }
    }
    async  viewRule(){
     
      }


      componentDidMount(){
        console.log('this.props', this.props)
      }



  async delete(id){
    const result = await deleteMaskById(id);
     toast.success('Se elimino la mascara', {
      position: toast.POSITION.BOTTOM_LEFT
    });
    window.location.reload()

    console.log('result', result,id)
  }

  render() {
    let date = this.props.mask
    let createAt = date? date.createdAt : ''
    if(this.state.newMask){
      return(
        <Redirect to={{
            pathname: '/maskbuilder',
            state: { form: this.props.form, idMask: this.props.mask.id }
          }} 
        />
      )
    }
    return (
          <MDBCol      size="3">
                <br/>
                <ToastContainer/>
                <MDBCard style={{ marginTop: "1rem" }} className="text-center">
                <MDBCardHeader color={config.color} ><MDBIcon onClick={()=>this.viewRule()}  onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})} icon="eye" style={{color: 'white'}} size="1x" className=" cursor"/></MDBCardHeader>
                <MDBCardBody>
                    <MDBCardTitle>{' version: ['+this.props.mask.maskVersion+']'}</MDBCardTitle>
                    <MDBBtn color="success" size="sm"  onClick={()=> this.setState({newMask: true})}>
                    Abrir Mascara
                    </MDBBtn>
                    <MDBBtn color="danger" size="sm" onClick={(e)=> this.delete(this.props.mask.id)}>
                    Borrar Mascara
                    </MDBBtn>
                    
                </MDBCardBody>
                {this.props.mask.createdAt&&(
                  <MDBCardFooter color={config.color}><b>creada el:</b> {createAt.split('T')[0] } - {createAt.split('T')[1].substring(0, 5) }</MDBCardFooter>
                )}
                </MDBCard>

        </MDBCol>
    );
  }
}

export default maskBox;