import React, { Component } from 'react';
import { MDBRow, MDBCol,MDBIcon} from "mdbreact";
import CardForm from './cardForm';
import NewForm from './btnNewForm';
import NewFormBK from './btnNewFormBK';
import { Form} from "../../data/index";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
const { GetAllFormulary, GetAllFormularyDataTable } = Form

class AllFormulary extends Component {
constructor(props){
        super(props)
        this.state = {
            data: [],
            formExist: false,
            message: false,
            gettingErrors:true,
            datagetter:true,
            dataOld:[],
            forms:[],
            loading:false,
            setLoading:false,
            currentPage:1,
            setCurrentPage:1,
            formsPerPage:40,
            setFormsPerPage:40,
            currentForms: []
        }
        this.dataForm = this.dataForm.bind(this);
    }
    async componentDidMount(){
        const datagetter = await GetAllFormularyDataTable();
        console.log('%^%%%$%$%$%$data: ',datagetter)
        if(datagetter.ok){
            this.setState({
                gettingErrors:false,
            })
        } 
        try{
            if(datagetter.data.Formulary.length>0){
                this.setState({
                    data: datagetter.data.Formulary,
                    dataOld:datagetter.data.Formulary,
                })
               
            }
        } catch(e){}
    }
    async dataForm(e){
        try{
        if(this.state.data.length>0){
            const datagetter = await GetAllFormulary();
            if( datagetter.data.Formulary.length===0){
                this.setState({
                    message: true
                    })
            }
            this.setState({
                data: datagetter.data.Formulary,
                setForms:datagetter.data.Formulary
            })
        }
    }catch(e){return false}
    }
    filterItems = (name, forms) => {
    let query = name.toLowerCase();
    return forms.filter(item => item.idForm.toLowerCase().indexOf(query) >= 0);
    }
    async seach(e){
        let res = this.filterItems(e.target.value,this.state.data)
        try{
            if(e.target.value === ""){
                this.setState({
                    data: this.state.dataOld
                })
                return
            }
            this.state.text  =[]
            if( res.length===0){
                this.setState({
                    message: true,
                    data: res
                    })
                    return
            }
            this.setState({
                data: res
            })
            
        }catch(e){
            return false
        } 
    }
    componentWillMount(){
        if(this.props.location.state){
            toast.error('Tienes que crear el formulario ['+this.props.location.state.idForm+'] antes de crear sus reglas', {
                position: toast.POSITION.TOP_CENTER
              });
        }
    }
  render() {
     return (
     <div className="App padding">
        <MDBRow>
            <MDBCol md="3"><NewForm handlerData={this.dataForm} /></MDBCol>
            <MDBCol  md="3"><NewFormBK handlerData={this.dataForm} /></MDBCol>
            <MDBCol  md="3"></MDBCol>
            <MDBCol  md="3"></MDBCol>
        </MDBRow>
        <hr/><br/>
            { (this.state.datagetter===false) && (<h3>Error de conexion</h3>) }
            {
                this.state.gettingErrors === false && this.state.data.length <=0 && 
                <div className="col-md-12">
                    <b><h3>No hay formularios registrados</h3></b>
                </div> 
            }
            {
                (this.state.gettingErrors) && (this.state.datagetter===true) &&
                (<div className="col-md-12">
                    <MDBIcon icon="cog" spin size="3x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed /><br></br>
                    <br></br>
                    <b><h3>Cargando...</h3></b>
                </div>)
            }     
            {/* {(!this.state.gettingErrors)&&(<b> Cargando...</b> && )}  */}
            {this.state.data.length>0 && 
                <CardForm data={this.state.data}/> 
            }
            {this.state.data.length<=4&&(
                <div>
                    <br/><br/><br/><br/><br/><br/><br/><br/>
                    <br/><br/><br/><br/><br/><br/><br/><br/>
                </div>
            )}
     </div>
    );
  }
}
export default AllFormulary;