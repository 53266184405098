import React, { Component } from 'react';
import { MDBRow,MDBDataTable, MDBCol,MDBIcon,MDBProgress,MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter,MDBBtn} from "mdbreact";

import {  Redirect } from 'react-router-dom';
import {  toast, ToastContainer } from 'react-toastify';
import RulesList from './rulesList';
import config from '../../config';
import './rules.css'

import {  Softexpert, Backend, Mask, Form, Roles} from "../../data/index";

const {GetForms,GetFormularyBySE} = Softexpert;
const {GetFormularyByServiceBK,} = Backend;
const {GetAllMaskVersionsPerForm} = Mask;
const {GetAllFormularyDataTable} = Form;
const {GetRolesByUserAndForm} = Roles
class Rules extends Component {

  constructor(props){
    super(props)
    this.state = {
      idForm: '',
      progress: 0,
      message: false,
      data:[],
      text: '',
      idFormSE: '',
      formExist: true,
      AllForm: [],
      loading: false
      
    }
    
    this.seachForm = this.seachForm.bind(this);
    this.idForm = this.idForm.bind(this);
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  async seachForm(idSE,id,idservice, provider){
    this.setState({
      progress: 60
    })

    const roles = await GetRolesByUserAndForm(localStorage.getItem("idUser"),id)
    if(!roles.data.rol){
      this.setState({
        progress: 0
      })
       toast.error('Usted no tiene permiso para ver este formulario', {
        position: toast.POSITION.BOTTOM_CENTER
      })
      return
    }
    
    if(provider==='bk'){
      let formBk = await GetFormularyByServiceBK(idservice)
      let arrayBkForm = []
      arrayBkForm[0] = JSON.parse(formBk.data.Form.fields)
      if(!formBk.data.Form.success){
        this.setState({
          progress: 0,
          message: true,
          text: 'El formulario tiene errores o no existe'
        })
      }
      this.setState({
        progress: 100,
        data:   await this.fields(arrayBkForm),
        formExist : formBk.data.Form.success,
        idFormSE: id  
      })
      
    }



    const data = await GetForms(idSE)
    
    if(data.ok){
          this.setState({
            progress: 80
          })
          if(data.data.hasOwnProperty('message')){
            this.setState({
              progress: 0,
              message: true,
              text: 'El formulario tiene errores o no existe'
            })
          
          }
          const result = await this.GetFormsById(idSE);
          this.setState({
            progress: 100,
            data:   await this.fields(data.data),
            formExist : result.ok,
            idFormSE: id  
          })
     }else{
          this.setState({
            progress: 0,
            message: true,
            text: data
        })
    }
  
  }
  idForm(e){
    this.setState({
      idForm: e.target.value
    })
  }
async AllmaskVersions(id){
  const maskData = await GetAllMaskVersionsPerForm(id);

   if(maskData.data.mask.length ===0){
    await toast.error('Este formulario no tiene version de Máscaras', {
      position: toast.POSITION.BOTTOM_CENTER
    })
  } else {
    this.toggle();
  }
  this.setState({
    maskStatus: await maskData.data.mask
  })
    try{
      for(let i = 0 ; i < this.state.maskStatus.length; i++){
        let formatedFieldsMasked = await this.state.maskStatus[i]['formatedFieldsMasked']
        let array = [];
        let version = await this.state.maskStatus[i]['maskVersion'];
        if(formatedFieldsMasked===null){
          let mask = await this.state.maskStatus[i]['fieldsMasked'].split("D")
          for(let n = 0 ; n <mask.length; n++){
            if(mask[n].length < 3 && mask[n]!==''){
            array.push('Mask No.' + mask[n] +'=>')
            } else{
              array.push(mask[n]+'\n')
            }
          }
          document.getElementById('status').value += "|| Version :" + version + "|| \n"+array+"\n"
        } else{
          let arrayFormated = formatedFieldsMasked.split('\\')
          let formated = [];
          document.getElementById('status').value+="|| Version :" + version + "|| \n"
          for(let i = 0 ; i < arrayFormated.length; i++){
            formated.push(arrayFormated[i])
            document.getElementById('status').value+= formated[i]+"\n"
          }
        }
      }
      }catch(e){
          console.log(e)
        }
      }
  async GetFormsById(id){
    const result = await GetFormularyBySE(id)
    return await result;
  }
  componentDidCatch(){
    this.AllmaskVersions();
  }
  async componentDidMount(){
    this.setState({
      loading: true
    })
    const data = await GetAllFormularyDataTable();
    let array = []
    if(data.data && data.data.Formulary.length>0){
        try{
          for (const i of data.data.Formulary) {
            console.log('i.url ', i )
            let element = {
              id: i.id,
              idForm: i.idForm,
              service: i.service_name,
              institution: i.institution_name,
              Reglas: <MDBBtn  className="btn  btn-md  text-white" color={config.color} onClick={ e =>this.seachForm (i.idForm, i.id, i.idservice,i.provider)}>Ver reglas</MDBBtn>,}
            array.push(element)
          }
        }catch(e){console.log(e)}
      const column = [
        {label:'id',field:'id',sort:'asc'},
        {label:'formulario',field:'idForm',sort:'asc'},
        {label:'Servicio',field:'service',sort:'asc'},
        {label:'Institucion',field:'institution',sort:'asc'},
        {label:'Accion',field:'Reglas',sort:'asc'},
      ]
      const result = {columns:column, rows: array}
      this.setState({
        AllForm: result,
        loading: false
      })
    }

  }
  fields(data){
    let page = []
    for (const key in data) { 
      let fieldFrom = []
      let field = [];
        for (const i in data[key]) {
              fieldFrom.push(data[key][i])
              field.push(data[key][i])
        }
        
        page.push([field,fieldFrom])
    }
    return page
  }
  render() {
    if(!this.state.formExist){
      return  <Redirect to={{
        pathname: "/Formulary",
        state:{
          formExist: false,
          idForm: this.state.idForm
        }
      }}/>
    }
    return (
      
      this.state.data.length>0? (
          <RulesList formulary={this.state.idFormSE} data={this.state.data}/>
      ):
      (
        <div>
        <ToastContainer/>
          <br/><br/><br/>
          <h1>Formularios para reglas</h1>
          <br/>
          <MDBRow>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg" >
                  <MDBModalHeader toggle={this.toggle}>
                    <center>Versiones de Mascaras</center>
                  </MDBModalHeader>
                  <MDBModalBody>
                  <div>
                    <center>
                    <div className="form-group">
                      <textarea
                      id="status"
                      readOnly
                      className="form-control"
                      rows="5"
                      />
                    </div>
                    </center>
                  </div>
                  </MDBModalBody>
                  <MDBModalFooter>
                  <MDBBtn  color="primary"  onClick={()=> this.toggle()}>Salir</MDBBtn>
                  </MDBModalFooter>
            </MDBModal>
          <MDBCol size="1"></MDBCol>
          <MDBCol size="10">
          <MDBProgress value={this.state.progress} color={config.color} className="my-1 text-white white"  />
          {
            this.state.message && (
              <div>
              <h4 className="text-danger"><b>{this.state.text.error}</b></h4>
              <h5 className="text-danger"><b>{this.state.text.data}</b></h5>
              <h6 className="text-danger"><b>{'CAMPO: '+this.state.text.field.name +'|| LABEL:'+this.state.text.field.label}</b></h6>
              </div>
            )
          }
          <MDBDataTable
            striped
            bordered
            hover
            data={this.state.AllForm}
          />
          {this.state.loading&&(
            <MDBIcon icon="cog" spin size="3x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed />
        )}
          </MDBCol>
          <MDBCol size="1"></MDBCol>
          </MDBRow>
      
        </div>
      ) 
    );
          }
    
}
export default Rules;